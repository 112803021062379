<template>
  <div class="bg-card" :style="{ padding }">
    <div class="header">

    </div>
    <main class="main">
      <slot/>
    </main>
  </div>
</template>
<script>
/**
 * @description 背景框
 */
export default {
  name: 'BGCard',
  props: {
    padding: {
      type: String,
      default: '0 4px'
    }
  }
}
</script>
<style lang="less" scoped>
  .bg-card{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header{
      height: 48px;
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      background-image: url(./img/cardbackground.png);
      background-size: cover;
    }
    .main{
      flex-grow: 1;
      height: 10px;
    }
  }
</style>