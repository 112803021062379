<template>
  <div class="up-and-down-layout">
    <div class="top">
      <slot name="top"></slot>
    </div>
    <div class="bottom" :style="`margin-top: ${ margin }px`">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>
<script>
/**
 * @description 上下布局
 */
export default {
  name: "UDLayout",
  props: {
    margin: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="less" scoped>
  .up-and-down-layout{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top{
      width: 100%;
    }
    .bottom{
      width: 100%;
      flex-grow: 1;
      height: 10px;
    }
  }
</style>
