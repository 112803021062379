<!--
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-07-19 17:05:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-27 10:41:11
-->
<template>
  <div class="page-header">
    <div class="left">
      <el-button class="back-btn" @click.stop="back" type="text">
        <i class="iconfont iconc-chevron-left" />
      </el-button>
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="right">
      <slot/>
    </div>
  </div>
</template>
<script>
/**
 * @description 页头
 */
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    back: {
      type: Function,
      default: () => { }
    }
  }
}
</script>
<style lang="less" scoped>
  .page-header{
    height: 40px;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .left{
      display: flex;
      align-items: center;
      .back-btn{
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid #E9EBF0;
      }
      .title{
        margin-left: 20px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #272829;
        line-height: 22px;
      }
    }
    .right{
      padding-right: 72px;
      :deep(.action-btn) {
        padding: 3px 16px;
        border-radius: 4px;
        & + .action-btn{
          margin-left: 12px;
        }
      }
    }
    
  }
</style>